import { Component } from '@angular/core';
import { UsuarioService } from 'src/providers/usuario-service';
import { UtilService } from 'src/providers/util-service';
import { AlertController, LoadingController } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { setCssColors } from 'src/app/colors';
import { environment } from '../../environment';
import { register } from 'swiper/element/bundle';
import OneSignal from 'onesignal-cordova-plugin';
import { OneSignal as One } from 'onesignal-ngx';
import { App } from '@capacitor/app';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ContratoMydasPage } from './contrato-mydas/contrato-mydas.page';

const versaoApp = environment.versaoAtual;

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  aparelho: string;
  plataforma: string;
  imei: string;

  usingInitialMenu = localStorage.getItem('menuinicial_app');

  public pushNotifications;

  constructor(
    public util: UtilService,
    private router: Router,
    public device: Device,
    public usuario: UsuarioService,
    public user: UsuarioService,
    private alertCtrl: AlertController,
    private oneSignalForWeb: One,
    private modalController: ModalController,
    private loadingController: LoadingController,
  ) {
    this.fetchDeviceInfo();
    this.askPushPermission();

    setInterval(() => this.updateUserInfo(), 15000);
    setInterval(() => this.pushSetup(), 3000);

    const homeApp = this.usingInitialMenu === '1' ? '/shortcuts' : '/principal';

    App.addListener('backButton', ({ canGoBack }) => {
      const currentUrl = this.router.url;

      if (!canGoBack || currentUrl === '/login' || currentUrl === homeApp) {
        App.exitApp();
      } else {
        this.closeOpenedView();
      }
    });
  }

  async pushSetup() {
    const alreadyHaveAlert = await this.alertCtrl.getTop();

    if (alreadyHaveAlert) return;

    const pushOption = localStorage.getItem('pushNotifications');
    if (pushOption) this.pushNotifications = pushOption;
  }

  async closeOpenedView() {
    const modal = await this.modalController.getTop();
    const alert = await this.alertCtrl.getTop();

    if (alert) {
      await alert.dismiss();
    } else if (modal) {
      await modal.dismiss();
    } else {
      window.history.back();
    }
  }

  async fetchDeviceInfo() {
    this.imei = await this.util.getDeviceID();
    this.plataforma = await this.util.getDevicePlatform();
    this.aparelho = await this.util.getDeviceModel();

    localStorage.setItem('imei', this.imei);
    localStorage.setItem('plataforma', this.plataforma);
    localStorage.setItem('aparelho', this.aparelho);
  }

  async checkContractsMydas() {
    if (!localStorage.getItem('hash')) return;

    const showPreTerms = localStorage.getItem('termos_pre_acesso');
    if (showPreTerms === '0') return;

    const isModalOpened = await this.modalController.getTop();
    if (isModalOpened) return;
    const customerDataStringified = localStorage.getItem('dados_cliente');
    const customerData = JSON.parse(customerDataStringified);
    const cpf = customerData.cpf;

    try {
      const res: any = await this.user.getContratosMydas(cpf);
      const contracts: Array<any> = res.msg;

      if (res.success === false) {
        console.warn('Erro ao obter contratos do mydas:', res);
        return;
      }

      let pendingContracts = [];

      const filterPendingContracts = (contract: any) => {
        if (contract.status === 'P') {
          pendingContracts.push(contract);
        }
      };

      const showContractModal = async () => {
        const modal = await this.modalController.create({
          component: ContratoMydasPage,
          backdropDismiss: false,
          componentProps: {
            pendingContracts: pendingContracts,
            customerCpf: cpf,
          },
        });

        await modal.present();

        const { data } = await modal.onDidDismiss();

        if (!data.noContractsPending) {
          await showContractModal();
        }
      };

      contracts.forEach((contract: any) => filterPendingContracts(contract));

      if (pendingContracts.length !== 0) {
        await showContractModal();
      }
    } catch (e) {
      console.error(e);
    }
  }

  // * precisamos configurar o SDK também pra ios
  // * https://documentation.onesignal.com/docs/ionic-capacitor-cordova-sdk-setup

  oneSignalInit() {
    if (!localStorage.getItem('chave_push')) {
      const chaveAleatoria = this.gerarChaveAleatoria(20);
      const clientId = localStorage.getItem('id_cliente');
      localStorage.setItem('chave_push', `${clientId}*${chaveAleatoria}`);
    }

    const externalId = localStorage.getItem('chave_push');
    localStorage.setItem('pushNotifications', 'false');

    try {
      OneSignal.Debug.setLogLevel(6);

      OneSignal.initialize(environment.chaveOneSignal);

      OneSignal.login(externalId);

      OneSignal.Notifications.requestPermission(true)
        .then((success: Boolean) => {
          console.log('Notification permission granted ' + success);
          this.updateOurAPIPushInfo();
        })
        .catch((error) => {
          localStorage.setItem('pushNotifications', 'false');
          console.error(error);
          this.util.showToast('Não foi possível configurar as notificações.');
        });
    } catch (error) {
      console.log(error);
      this.util.showToast('Não foi possível configurar as notificações.');
    }
  }

  async oneSignalWeb() {
    if (!localStorage.getItem('hash')) return;

    const isOneSignalInstalled = await this.isOneSignalInstalled();

    if (isOneSignalInstalled) {
      console.warn('OneSignal já está instalado, mudando externalId');
      this.oneSignalWebConfiguration();
      this.updateOurAPIPushInfo();
      return;
    }

    this.oneSignalForWeb
      .init({
        appId: environment.chaveOneSignal,
        serviceWorkerParam: { scope: '/' },
        serviceWorkerPath: '/OneSignalSDKWorker.js',
        allowLocalhostAsSecureOrigin: true,
      })
      .then(async () => {
        await this.oneSignalWebConfiguration();
        await this.updateOurAPIPushInfo();
      })
      .catch((erro) => {
        localStorage.setItem('pushNotifications', 'false');
        console.error('Erro ao inicializar OneSignal:', erro);
        this.util.showToast('Erro ao configurar suas notificações de push!');
      });
  }

  async oneSignalWebConfiguration() {
    this.oneSignalForWeb.Debug.setLogLevel('info');

    if (!localStorage.getItem('chave_push')) {
      const chaveAleatoria = this.gerarChaveAleatoria(20);
      const clientId = localStorage.getItem('id_cliente');
      localStorage.setItem('chave_push', `${clientId}*${chaveAleatoria}`);
    }

    await this.oneSignalForWeb.Slidedown.promptPush({ force: true });
    await this.oneSignalForWeb.login(localStorage.getItem('chave_push'));
  }

  async updateOurAPIPushInfo() {
    const request = {
      token: localStorage.getItem('hash'),
      player: localStorage.getItem('chave_push'),
      imei: this.imei,
      aparelho: this.aparelho,
      plataforma: this.plataforma,
    };

    const res: any = await this.usuario.atualizarPush(request);

    if (res.success) {
      console.log('Success', res);
      localStorage.setItem('pushNotifications', 'true');
    } else {
      localStorage.setItem('pushNotifications', 'false');
      console.error('Erro na API Hapolo', res);
      this.util.showToastError('Erro ao configurar suas notificações de push!');
    }
  }

  async changePushOption(option: string) {
    let msg: string;

    try {
      // options: '0' or '1'
      const requestData = {
        token: localStorage.getItem('hash'),
        chave_push: localStorage.getItem('chave_push'),
        option: option,
      };

      const res: any = await this.usuario.edit_push(requestData);

      console.log(res);

      if (res.success) {
        if (option === '1') {
          localStorage.setItem('pushNotifications', 'true');
          msg = 'Você habilitou o recebimento de notificações.';
          await this.util.showToastSuccess(msg);
        } else {
          localStorage.setItem('pushNotifications', 'false');
          msg = 'Você desabilitou o recebimento de notificações.';
          await this.util.showToastWarning(msg);
        }
      } else {
        msg = 'Houve um erro ao fazer tentar fazer a alteração.';
        await this.util.showToastError(msg);
      }
    } catch (e) {
      console.error(e);
      msg = 'Erro na requisição, ente novamente mais tarde ...';
      this.util.showToastWarning(msg);
    }
  }

  async handlePushOptions() {
    if (!localStorage.getItem('pushNotifications')) return;

    const loading = await this.loadingController.create({
      message: 'Configurando ...',
    });

    const handleSetPush = async (option: string) => {
      await closeAlert();
      await loading.present();
      await this.changePushOption(option);
      await loading.dismiss();
    };

    const closeAlert = async () => {
      const alert = await this.alertCtrl.getTop();

      if (alert) {
        await alert.dismiss();
      }
    };

    const alert = await this.alertCtrl.create({
      header: 'Você deseja receber notificações de push?',
      buttons: [
        {
          text: 'Não',
          handler: () => handleSetPush('0'),
        },
        {
          text: 'Sim',
          handler: () => handleSetPush('1'),
        },
      ],
    });
    await alert.present();
  }

  async isOneSignalInstalled() {
    const registrations = await navigator.serviceWorker.getRegistrations();
    return registrations.some(
      (registration) =>
        registration.active &&
        registration.active.scriptURL.includes('OneSignalSDKWorker.js'),
    );
  }

  // * refatorar isso?
  // * só formatei o código, mas isso poderia ser um laço e repetição for?
  updateUserInfo() {
    console.info('Updating user info ...');

    // this.checkContractsMydas();

    let request = { token: localStorage.getItem('hash') };
    if (localStorage.getItem('url_padrao') != null) {
      if (localStorage.getItem('url_padrao')) {
        this.user.getMenu(request).then((response) => {
          const res = JSON.parse(JSON.stringify(response));
          if (res.success === true) {
            localStorage.setItem(
              'opcoes_tela_principal',
              res.dados_empresa.opcoes_tela_principal,
            );

            localStorage.setItem(
              'exibe_parceiros',
              res.dados_empresa.exibe_parceiros,
            );
            localStorage.setItem(
              'bloqueio_msg',
              res.dados_empresa.mensagem_bloqueio,
            );
            localStorage.setItem(
              'dados_cliente',
              JSON.stringify(res.dados_cliente),
            );

            localStorage.setItem(
              'pode_relatorio',
              JSON.stringify(res.dados_cliente.pode_relatorio),
            );

            localStorage.setItem(
              'pago',
              JSON.stringify(res.dados_cliente.pago),
            );

            localStorage.setItem(
              'situacao_sga',
              res.dados_cliente.situacao_sga,
            );
            localStorage.setItem('tem_rotas', res.dados_cliente.tem_rotas);
            localStorage.setItem(
              'dados_empresa',
              JSON.stringify(res.dados_empresa),
            );
            localStorage.setItem(
              'dns_empresa',
              res.dados_empresa.dados_login.dns,
            );
            localStorage.setItem('color', '#b134eb');
            let cor = '#' + res.dados_empresa.cor_app;
            localStorage.setItem('atendimento', res.dados_empresa.atendimento);
            localStorage.setItem(
              'whatsapp_telefone',
              res.dados_empresa.whatsapp_telefone,
            );
            localStorage.setItem(
              'whatsapp_mensagem',
              res.dados_empresa.whatsapp_mensagem,
            );
            localStorage.setItem(
              'link_personalizado',
              res.dados_empresa.link_personalizado,
            );
            localStorage.setItem(
              'mensagem_personalizado',
              res.dados_empresa.mensagem_personalizado,
            );
            localStorage.setItem('dashapp', res.dados_empresa.dashapp);
            localStorage.setItem('cor', cor);
            localStorage.setItem('id_admin', res.dados_cliente.id_admin);
            this.setCor();
            localStorage.setItem('logomarca', res.dados_empresa.logo2);
            localStorage.setItem('favicon', res.dados_empresa.logo3);
            localStorage.setItem(
              'exibir_contratos_app',
              res.dados_empresa.exibir_contratos_app,
            );
            localStorage.setItem(
              'exibe_arquivo',
              res.dados_empresa.exibe_arquivo,
            );
            localStorage.setItem(
              'exibe_contatos',
              res.dados_empresa.exibe_contatos,
            );

            localStorage.setItem('exibe_chat', res.dados_empresa.exibe_chat);
            localStorage.setItem(
              'exibe_indique_um_amigo',
              res.dados_empresa.exibe_indique_um_amigo,
            );
            localStorage.setItem(
              'exibe_sobre_empresa',
              res.dados_empresa.exibe_sobre_empresa,
            );
            if (
              res.dados_cliente.tipo === 'A1' ||
              res.dados_cliente.tipo === 'A2' ||
              res.dados_cliente.tipo === 'A3'
            ) {
              localStorage.setItem('exibe_parametros', '1');
            } else {
              localStorage.setItem(
                'exibe_parametros',
                res.dados_cliente.exibe_parametros,
              );
            }
            localStorage.setItem(
              'numero_atendimento',
              res.dados_empresa.numero_atendimento,
            );
            localStorage.setItem(
              'exibe_faturamento',
              res.dados_empresa.exibe_faturamento,
            );
            localStorage.setItem(
              'exibir_termo_app',
              res.dados_empresa.exibir_termo_app,
            );
            localStorage.setItem(
              'menuinicial_app',
              res.dados_empresa.menuinicial_app,
            );
            localStorage.setItem(
              'permitir_obs_app',
              res.dados_empresa.permitir_obs_app,
            );
            localStorage.setItem(
              'exibir_saude',
              res.dados_empresa.exibir_saude,
            );
            localStorage.setItem(
              'exibir_eventos',
              res.dados_empresa.exibir_eventos,
            );
            localStorage.setItem(
              'mostrar_boletos',
              res.dados_empresa.mostrar_boletos,
            );
            localStorage.setItem(
              'selfie_ass_contrato',
              res.dados_empresa.selfie_ass_contrato,
            );
            localStorage.setItem(
              'termos_pre_acesso',
              res.dados_empresa.termos_pre_acesso,
            );
            localStorage.setItem(
              'codigo_associado',
              res.dados_cliente.codigos_associado,
            );
            localStorage.setItem(
              'sistema_pontos',
              res.dados_empresa.sistema_de_pontos ? '1' : '0',
            );
            localStorage.setItem(
              'bg_cartao_v2_frente',
              res.dados_empresa.bg_cartao_v2_frente,
            );
            localStorage.setItem(
              'bg_cartao_v2_verso',
              res.dados_empresa.bg_cartao_v2_verso,
            );
            localStorage.setItem(
              'cartao_associado_v2',
              res.dados_empresa.cartao_associado_v2,
            );

            localStorage.setItem(
              'mostrar_clube_gas',
              res.dados_empresa.mostrar_clube_gas,
            );
            localStorage.setItem(
              'url_clube_gas',
              res.dados_empresa.url_clube_gas,
            );
            localStorage.setItem(
              'mostrar_odonto_clube',
              res.dados_empresa.mostrar_odonto_clube,
            );
            localStorage.setItem(
              'url_odonto_clube',
              res.dados_empresa.url_odonto_clube,
            );
            localStorage.setItem(
              'mostrar_doc24',
              res.dados_empresa.mostrar_doc24,
            );
            localStorage.setItem('url_doc24', res.dados_empresa.url_doc24);
            localStorage.setItem(
              'url_eventos_sga',
              res.dados_empresa.url_eventos_sga,
            );
            localStorage.setItem(
              'mostrar_posto_bufon',
              res.dados_empresa.mostrar_posto_bufon,
            );
            localStorage.setItem(
              'url_posto_bufon',
              res.dados_empresa.url_posto_bufon,
            );
            localStorage.setItem(
              'nome_topo_app',
              res.dados_empresa.nome_topo_app,
            );

            localStorage.setItem('app_atualizado', 'true');
            localStorage.setItem('app_url', '');
            var lg = res.dados_empresa.dados_login;
            var versao = '';
            var url = '';
            if (
              this.plataforma === 'Android' ||
              this.plataforma === 'android'
            ) {
              versao = lg ? lg['versao_android'] : '';
              url = lg ? lg['url_android'] : '';
            } else {
              versao = lg ? lg['versao_ios'] : '';
              url = lg ? lg['url_ios'] : '';
            }
            if (versao) {
              if (versao.includes('.')) {
                var teste1 = versaoApp.split('.');
                var teste2 = versao.split('.');
                if (versao !== versaoApp) {
                  if (teste1.length > 2) {
                    var a = parseInt(teste1[0]);
                    var b = parseInt(teste1[1]);
                    var c = parseInt(teste1[2]);

                    var a2 = parseInt(teste2[0]);
                    var b2 = parseInt(teste2[1]);
                    var c2 = parseInt(teste2[2]);

                    var atualizado = false;

                    if (a > a2) {
                      atualizado = true;
                    } else {
                      if (b > b2) {
                        atualizado = true;
                      } else {
                        if (c > c2) {
                          atualizado = true;
                        }
                      }
                    }
                    localStorage.setItem('app_atualizado', '' + atualizado);
                    localStorage.setItem('app_url', url);
                    localStorage.setItem('app_versao', versao);
                  }
                }
              }
            }
          }
        });
      }
    }
  }

  setCor() {
    const mainbg = localStorage.getItem('cor') || '#326d8c';

    setCssColors(mainbg);
  }

  gerarChaveAleatoria(tamanho: number) {
    const caracteres =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let chave = '';
    for (let i = 0; i < tamanho; i++) {
      const indice = Math.floor(Math.random() * caracteres.length);
      chave += caracteres.charAt(indice);
    }
    return chave;
  }

  async avisoPushNegativo() {
    const alert = await this.alertCtrl.create({
      header:
        'Suas notificações push estão desativadas, por favor abra as configurações do aplicativo, e ative-as.',
      buttons: [
        {
          text: 'OK',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  private async oneSignalPushOption() {
    const alert = await this.alertCtrl.create({
      header: 'Deseja receber notificações de rastreamento?',
      buttons: [
        {
          text: 'Sim',
          handler: () => {
            this.oneSignalForWeb.User.PushSubscription.optIn();
          },
        },
        {
          text: 'Não',
          handler: () => {
            this.oneSignalForWeb.User.PushSubscription.optOut();
          },
        },
      ],
    });

    await alert.present();
  }

  private async promptForNativeBuild() {
    const alert = await this.alertCtrl.create({
      header: 'Gostaria de receber notificações push?',
      buttons: [
        {
          text: 'SIM',
          handler: () => {
            this.oneSignalInit();
          },
        },
        {
          text: 'Talvez mais tarde',
          handler: () => {
            localStorage.setItem('pushNotifications', 'false');
          },
        },
      ],
    });

    //show modal in only first login
    if (
      localStorage.getItem('hash') &&
      !localStorage.getItem('pushNotifications')
    ) {
      await alert.present();
    }
  }

  async askPushPermission() {
    if (localStorage.getItem('pushNotifications')) return;

    // ? case PWA/Web
    if (this.plataforma === 'web') this.oneSignalWeb();

    // ? case android/ios
    if (this.plataforma !== 'web') this.promptForNativeBuild();
  }

  async clearAllData() {
    // Limpar LocalStorage
    localStorage.clear();

    // Limpar SessionStorage
    sessionStorage.clear();

    // Limpar IndexedDB
    const indexedDBs = await indexedDB.databases();
    for (const db of indexedDBs) {
      indexedDB.deleteDatabase(db.name);
    }

    // Limpar Cookies
    document.cookie.split(';').forEach((cookie) => {
      const name = cookie.split('=')[0].trim();
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
    });

    // Limpar Cache
    if ('caches' in window) {
      const cacheKeys = await caches.keys();
      for (const key of cacheKeys) {
        await caches.delete(key);
      }
    }
  }
}
