import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CartaoAssociadoComponent } from './cartao-associado.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [CartaoAssociadoComponent],
  exports:[CartaoAssociadoComponent]
})
export class CartaoAssociadoModule {}
