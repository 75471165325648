import { Component } from '@angular/core';
import { UsuarioService } from 'src/providers/usuario-service';
import { UtilService } from 'src/providers/util-service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-cartao-associado',
  templateUrl: './cartao-associado.component.html',
  styleUrls: ['./cartao-associado.component.scss'],
})

export class CartaoAssociadoComponent {
  noImageLink = '/assets/imgs/noImage.png';
  mainbg: string;
  txtNome: any;
  txtCpf: any;
  txtSituacao: any;
  txtStatus: any;
  showCard;
  bgCartao;
  noBgColor = '#002135';
  title;
  favicon;
  loaded = false;
  produtos = [];
  dependentes = '';
    
    constructor(
      public usuario: UsuarioService,
      public util: UtilService,
      private sanitizer: DomSanitizer
    ) {
      this.loaded = false;
      this.mainbg = localStorage.getItem('cor');
      this.getBgCartao();
      this.getDependentes();
      this.getProducts();
  
  
      const status = localStorage.getItem('situacao_sga');
      if (status == "0") {
        this.txtStatus = "INATIVO";
      } else if (status != null) {
        this.txtStatus = status;
      }

      const empresa = JSON.parse(localStorage.getItem('dados_empresa'));
      if (empresa.menuinicial_app === '1') {
        this.showCard = true;
      }
      if (empresa != null) {
        const name = empresa.nome_empresa;
        if (name.startsWith('Star')) {
          this.title = 'Star Proteção Veícular + Saúde';
        } else {
          this.title = 'Saúde';
        }
      }
  
      this.favicon = localStorage.getItem('favicon');
      if (this.favicon === '/media/system/carta-icon-png.png') {
        this.favicon = '/assets/imgs/triangulog.png';
      } else {
        this.favicon =
          this.util.obterHostPadraoDaApi().replace('/api_app', '') + this.favicon;
      }

      const user = JSON.parse(localStorage.getItem('dados_cliente'));
      if (user != null) {
        this.txtNome = user.nome;
        this.txtCpf = user.cpf;
      }
    }
  
    getBgCartao() {
      const partialUrl = localStorage.getItem('bg_cartao_associado');
      //Exemplo:   '/media/bg_cartao_associado_keUfZIL.png'
      if (partialUrl !== '') {
        const url = 'https://web.hapolo.com.br/media' + partialUrl;
        console.log('aaaaaa', url)
  
        const safe = this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  
        this.bgCartao = safe;
      } else {
        this.bgCartao = '';
      }
    }
  
    ionViewDidEnter() {
      this.mainbg = localStorage.getItem('cor') || '#FFF';
      document.body.style.setProperty('--ion-background-color', this.mainbg);
      this.getDependentes();
    }
  
    getDependentes() {
      const request = { token: localStorage.getItem('hash') };
      this.usuario.getDependentes(request).then((response) => {
          let res = JSON.parse(JSON.stringify(response));
          res = res.msg;
          const lista = [];
          res.forEach((element) => {
            lista.push(element.nome);
          });
  
          const nomes = lista.join(', ');
  
          this.dependentes = nomes;

          if (!nomes){
            this.dependentes = "Nenhum";
          }

          console.log(this.dependentes);
        })
        .catch((response) => {});
      }
  
    async getProducts() {
      const request = { token: localStorage.getItem('hash') };
      let res;
      await this.usuario.getProdutosIntegrados(request).then((response) => {
        if (this.util.logoutUser(response)) {
          this.usuario.logoutUser();
          this.util.showToast(this.util.msgExpira);
          //sair
        }
        res = JSON.parse(JSON.stringify(response));
      });
      this.produtos = res.msg;
    }
  
    getSrc(x) {
      if (x !== '') {
        const url = 'https://web.hapolo.com.br/media/' + x.toString();
        return url;
      }
    }  
  }