/* eslint-disable prefer-const */
import { Injectable, Inject } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { Device as CapDevice } from '@capacitor/device'
import { Network } from '@ionic-native/network/ngx';
import { environment } from '../../environment';

import {
  LoadingController,
  ToastController,
  AlertController,
  Platform,
} from '@ionic/angular';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  msgExpira = 'Sessão expirada. Favor Logar novamente';

  constructor(
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public platform: Platform,
    public network: Network,
    public device: Device
  ) {}

  public async getDeviceModel(): Promise<string> {
    try {
      const info = await CapDevice.getInfo();
      return info.model;
    } catch (error) {
      return 'nao_permitido';
    }
  }

  public async getDeviceID(): Promise<string> {
    try {
      const capIdentifier = await CapDevice.getId();
      return capIdentifier.identifier;
    } catch (error) {
      console.error('Error getting device ID', error);
      return '0000';
    }
  }

  public async getDevicePlatform(): Promise<string> {
    try {
      const capIdentifier = await CapDevice.getInfo();
      return capIdentifier.platform;
    } catch (error) {
      console.error('Error getting device ID', error);
      return '0000';
    }
  }

  public obterHostDaApi(): string {
    //this.verificaInternet();
    const url = localStorage.getItem('url_padrao');
    if (url) {
      return url.replace('http://', '').replace('/', '');
    } else {
      return 'http://web.hapolo.com.br';
    }
  }

  public getGoogleApiKey(): string {
    return JSON.parse(localStorage.getItem('dados_empresa')).googleapikey;
  }

  public getMapBoxCssStreet(): string {
    return JSON.parse(localStorage.getItem('dados_empresa')).linkmapstreet;
  }
  public getMapBoxCssSatelite(): string {
    return JSON.parse(localStorage.getItem('dados_empresa')).linkmapsatelite;
  }

  public getIpMydas(): string {
    return 'https://painel-mydas.com.br';
    //return JSON.parse(localStorage.getItem('dados_empresa')).ip_mydas;
  }

  public getTokenMapBox(): string {
    return JSON.parse(localStorage.getItem('dados_empresa')).mapboxapikey;
  }

  public getIntegracaoSga() {
    return {
      ip: environment.ip, //str
      apelido: environment.apelido, //str
      admin: environment.admin, //int
      allowAccountCreation: environment.allowAccountCreation, //permite criacao de conta
    };
  }

  public obterLogoEmpresa(telaLogin = false): string {
    const logoempresa = localStorage.getItem('favicon');
    let url = `/administracao/imagensapoloapp/${environment.urlImageHapolo}`;
    if (logoempresa == null) {
      return this.obterHostFilesDaApi() + url;
    } else {
      if (telaLogin === true) {
        if (
          logoempresa != null &&
          (url === '/administracao/imagensapoloapp/hapoloprincipal.png' ||
            url === '/administracao/imagensapoloapp/trackernetprincipal.png')
        ) {
          return this.obterHostFilesDaApi() + logoempresa;
        } else {
          return this.obterHostFilesDaApi() + url;
        }
      } else {
        return this.obterHostFilesDaApi() + logoempresa;
      }
    }
  }

  public obterHostFilesDaApi(): string {
    return 'https://web.hapolo.com.br';
  }

  public obterHostPadraoDaApi(): string {
    if (this.verificaInternet()) {
    }
    return 'https://web.hapolo.com.br/api_app';
  }

  public showLoading(message: string = 'Carregando...'): any {
    const loading = this.loadingCtrl.create({
      message,
    });

    return loading;
  }

  public logoutUser(retorno): any {
    if (retorno.msg === 'Invalid session token') {
      return 1;
    }
    return 0;
  }

  public validEmail(email) {
    return /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(email);
  }

  public async showToast(
    message: string,
    position: 'top' | 'bottom' | 'middle' = 'top'
  ): Promise<any> {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position,
      color: 'light',
      buttons: [
        {
          role: 'cancel',
          icon: 'close-outline'
        }
      ]
    });

    await toast.present();
  }

  public async showToastSuccess(
    message: string,
    position: 'top' | 'bottom' | 'middle' = 'top'
  ): Promise<any> {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position,
      color:'success',
      buttons: [
        {
          role: 'cancel',
          icon: 'close-outline'
        }
      ]
    });

    await toast.present();
  }

  public async showToastError(
    message: string,
    position: 'top' | 'bottom' | 'middle' = 'top'
  ): Promise<any> {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position,
      color: 'danger',
      buttons: [
        {
          role: 'cancel',
          icon: 'close-outline'
        }
      ]
    });

    await toast.present();
  }

  async showToastWarning(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top',
      color: 'warning',
      buttons: [
        {
          role: 'cancel',
          icon: 'close-outline',
        },
      ],
    });
    await toast.present();
  }

  public async showAlert(
    message: string,
    title: string = 'Atenção',
    mensagem: string = ''
  ) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: message,
      message: mensagem,
      buttons: ['OK'],
    });
    await alert.present();
  }

  public async showAlertCallBack(
    message: string,
    title: string = 'Atenção',
    callback
  ) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: message,
      buttons: [
        {
          text: 'OK',
          handler: callback,
        },
      ],
    });
    await alert.present();
  }

  public isJson(json: string): boolean {
    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }

    return true;
  }

  showMessageError(response: Response) {
    if (response.status === 401) {
      this.showAlertCallBack(
        'Autorização expirada, é necessário que se autentique novamente.',
        null,
        () => {
          localStorage.clear();
          window.location.reload();
        }
      );
    } else {
      //this.showToast('A operação falhou!');
      //this.showToast(response['_body'] + response.statusText + response.status + response.text);
    }
  }

  public verificaInternet(): any {
    this.network.onDisconnect().subscribe(
      (data) => {
        if (data.type === 'offline') {
          //this.showToast('Sua internet parece não esta disponivel!');
          return false;
        } else {
          //this.showToast('Conexão com a internet estabelecida');
          return true;
        }
      },
      (error) => console.log(error)
    );
  }

  isMobile(): boolean {
    return (
      !document.URL.startsWith('http') ||
      document.URL.startsWith('http://localhost:8080')
    );
  }

  showNotificationBrowser(title: string, message: string, icon: string = '') {
    //Verifico se tem suporte e permissão a notificações do browser.
    if (typeof Notification != 'undefined' && Notification != null) {
      const notificationBrowser = Notification as any;

      if (notificationBrowser && notificationBrowser.permission !== 'granted') {
        //Solicito permissão!
        Notification.requestPermission();
      }
      if (icon === '') {
        new Notification(title, {
          body: message,
        });
      } else {
        new Notification(title, {
          icon,
          body: message,
        });
      }
    }
  }
}
