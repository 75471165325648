import { Component, Input } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { UtilService } from 'src/providers/util-service';
import { UsuarioService } from 'src/providers/usuario-service';
import { ModalController } from '@ionic/angular';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-contrato-mydas',
  templateUrl: './contrato-mydas.page.html',
  styleUrls: ['./contrato-mydas.page.scss'],
})
export class ContratoMydasPage {
  @Input() pendingContracts: Array<any>;
  @Input() customerCpf: string;

  platform: string = localStorage.getItem('plataforma');
  customerColor: string = localStorage.getItem('cor');

  contractOpened: boolean = false;
  loading: HTMLIonLoadingElement;
  isLoading: boolean = false;
  boundOnFocus;

  constructor(
    private loadingController: LoadingController,
    private utilService: UtilService,
    private userService: UsuarioService,
    private modalController: ModalController,
  ) {
    this.boundOnFocus = this.onFocus.bind(this);
  }

  async onFocus() {
    if (this.contractOpened) {
      if (!this.isLoading) {
        try {
          this.isLoading = true;

          this.loading = await this.loadingController.create({
            message: 'Verificando assinatura do contrato',
          });

          await this.loading.present();

          const res: any = await this.userService.getContratosMydas(
            this.customerCpf,
          );
          const contracts: Array<any> = res.msg;

          this.pendingContracts = [];
          contracts.forEach((contract) => this.checkSigned(contract));

          if (this.pendingContracts.length === 0) {
            const canDismiss = {
              noContractsPending: true,
            };

            window.removeEventListener('focus', this.boundOnFocus);

            App.removeAllListeners();

            await this.modalController.dismiss(canDismiss);
            await this.loading.dismiss();
            this.utilService.showToastSuccess(
              'Você assinou os contratos pendentes.',
            );
          } else {
            this.utilService.showToastWarning(
              'Assine os contratos para continuar.',
            );
            await this.loading.dismiss();
          }

          this.isLoading = false;
        } catch (error) {
          this.utilService.showToastError(
            'Houve um erro ao verificar seus contratos, tente novamente mais tarde ...',
          );
          console.error(error);
        }
      }
    }
  }

  checkSigned(contract: any) {
    if (contract.status === 'P') {
      this.pendingContracts.push(contract);
    }
  }

  openContractLink(link: string) {
    if (this.platform === 'web') {
      this.webFocusChangeSetup();
    } else {
      this.nativeFocusChangeSetup();
    }

    this.contractOpened = true;
    window.open(link);
  }

  webFocusChangeSetup() {
    window.addEventListener('focus', this.boundOnFocus);
  }

  nativeFocusChangeSetup() {
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        this.onFocus();
      }
    });
  }
}
