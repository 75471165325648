import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import {
  ActionSheetController,
  AlertController,
  IonicModule,
  IonicRouteStrategy,
  MenuController,
  ModalController,
  NavController,
  NavParams,
} from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UtilService } from 'src/providers/util-service';
import { Network } from '@ionic-native/network/ngx';
import { Device } from '@ionic-native/device/ngx';
import { UsuarioService } from 'src/providers/usuario-service';
import {
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { SgaService } from 'src/providers/sga';
import { VeiculoService } from 'src/providers/veiculo-service';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Events } from 'src/providers/Events';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { CameraModalComponent } from './camera-modal/camera-modal.component';
import { ShowPictureComponent } from './show-picture/show-picture.component';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { CartaoAssociadoModule } from './components/cartao-associado/cartao-associado.module';

@NgModule({
  declarations: [AppComponent, Nl2brPipe, CameraModalComponent, ShowPictureComponent, SignaturePadComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    Network,
    Camera,
    Device,
    HttpClient,
    UtilService,
    UsuarioService,
    SgaService,
    VeiculoService,
    File,
    Events,
    MenuController,
    ActionSheetController,
    ModalController,
    AlertController,
    InAppBrowser,
    LocationAccuracy,
    FileOpener,
    Clipboard,
    OneSignal,
    NavParams,
    NavController,
    RouterModule,
    SplashScreen,
    Geolocation,
    CallNumber,
    SocialSharing,
    LocationAccuracy,
    CartaoAssociadoModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
  exports: [Nl2brPipe, SignaturePadComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
